import { CIcon } from '~/icons/types'
export const ciClassifiedTransfer: CIcon = {
  name: 'classified-transfer',
  type: 'regular',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'm488.8 230.5-90.6-90.6c-4.5-4.5-10.7-7.1-17.1-7.1H236.6c-36.1 0-65.5 29.4-65.5 65.5v1.7c0 13.3 10.8 24.2 24.2 24.2s24.2-10.8 24.2-24.2v-1.7c0-9.5 7.7-17.2 17.2-17.2H337v86.5c0 13.3 10.8 24.2 24.2 24.2h86.5v154.7c0 9.5-7.7 17.2-17.2 17.2H236.6c-9.5 0-17.2-7.7-17.2-17.2v-85.4c0-13.3-10.8-24.2-24.2-24.2S171 347.7 171 361.1v85.4c0 36.1 29.4 65.5 65.5 65.5h193.8c36.1 0 65.5-29.4 65.5-65.5v-199c.1-6.4-2.4-12.5-7-17zm-103.6-35.3 48.4 48.4h-48.4v-48.4z',
      fill: 'currentColor'
    },
    {
      d:
        'M111.7 330.9H65.5c-9.5 0-17.2-7.7-17.2-17.2V65.5c0-9.5 7.7-17.2 17.2-17.2h193.8c9.5 0 17.2 7.7 17.2 17.2v11c0 13.3 10.8 24.2 24.2 24.2 13.3 0 24.2-10.8 24.2-24.2v-11c0-36.1-29.4-65.5-65.5-65.5H65.5C29.4 0 0 29.4 0 65.5v248.2c0 36.1 29.4 65.5 65.5 65.5h46.2c13.3 0 24.2-10.8 24.2-24.2-.1-13.3-10.9-24.1-24.2-24.1z',
      fill: 'currentColor'
    },
    {
      d:
        'M110 280.8c0 13.3 10.8 24.2 24.2 24.2h101.2l-.1.2c-9.1 9.8-8.5 25.1 1.3 34.1 4.6 4.3 10.5 6.4 16.4 6.4 6.5 0 13-2.6 17.7-7.7l37.7-40.7 1.5-1.8v-.1c.5-.6.9-1.3 1.3-2v-.1c.4-.7.8-1.3 1.1-2 0 0 0-.1.1-.1l.9-2.1v-.1c.3-.7.5-1.4.7-2.2.4-1.4.6-2.9.7-4.5v-2.6c0-.7-.1-1.4-.2-2.1v-.2c-.1-.7-.2-1.4-.4-2.1v-.2l-.6-2.1c0-.1 0-.1-.1-.2-.2-.7-.5-1.3-.8-1.9 0-.1-.1-.2-.1-.3l-.9-1.8c-.1-.1-.1-.2-.2-.3-.3-.6-.7-1.1-1.1-1.7-.1-.1-.1-.2-.2-.3-.4-.6-.8-1.1-1.3-1.6-.1-.1-.1-.2-.2-.2-.5-.6-1-1.1-1.6-1.6l-.1-.1-40.7-37.7c-9.8-9.1-25.1-8.5-34.1 1.3-7.8 8.4-8.4 20.9-2.2 30h-95.8c-13.3.1-24.1 10.9-24.1 24.2z',
      fill: 'currentColor'
    }
  ]
}
