




























































































import { I18nLocale } from '~/models/shared/types'
import { mapGetters } from 'vuex'
import { I18N_NS } from '~/store/modules/shared/i18n/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    subLinks: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    useNuxtLink: {
      type: Boolean,
      required: false,
      default: false
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: [Number, String],
      required: false,
      default: ''
    },
    selectedCheckbox: {
      type: [Number, String],
      required: false,
      default: ''
    },
    icon: {
      type: Object,
      required: false,
      default: null
    },
    cicon: {
      type: Object,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    clickFunction: {
      type: Function,
      required: true
    },
    navigateFunction: {
      type: Function,
      required: true
    },
    flags: {
      type: Boolean,
      required: false,
      default: false
    },
    /*
     * Available locales should always be passed when flags=true
     * */
    availableLocales: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(I18N_NS, { locale: 'locale' }),
    hasSubmenu() {
      return (
        (this.subLinks && this.subLinks.length) ||
        this.hasSubmenuSlot ||
        this.flags
      )
    },
    hasSubmenuSlot() {
      return !!this.$slots.submenu
    },
    hasTitleSlot() {
      return !!this.$slots.title
    },
    localeCodeToFlag: () =>
      new Map([
        [
          I18nLocale.EN,
          { icon: require('~/assets/img/flags/1x1/en.svg'), title: 'English' }
        ],
        [
          I18nLocale.EL,
          { icon: require('~/assets/img/flags/1x1/el.svg'), title: 'Ελληνικα' }
        ]
      ])
  }
})

// FOR STYLES USE ::v-deep IN PARENT COMPONENT
