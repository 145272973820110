import { CIcon } from '~/icons/types'

export const ciSaleRequest: CIcon = {
  width: 26,
  height: 23,
  paths: [
    {
      d:
        'M6.188 20.011c-1.005 0-1.79-.357-2.354-1.071-.564-.714-.846-1.743-.846-3.087s.282-2.373.846-3.087c.564-.714 1.349-1.071 2.354-1.071.67 0 1.227.157 1.669.472.45.307.8.737 1.051 1.29l-.742.46a2.16 2.16 0 0 0-.743-.99c-.336-.253-.747-.38-1.235-.38-.602 0-1.09.18-1.463.542-.373.36-.605.84-.697 1.44h2.938v.771H3.983v1.106h2.983v.772H4.028c.1.599.332 1.078.697 1.44.374.36.861.54 1.463.54.503 0 .926-.134 1.269-.402.343-.27.598-.622.766-1.06l.743.495a4.056 4.056 0 0 1-.435.726 2.653 2.653 0 0 1-.594.576 2.65 2.65 0 0 1-.766.38c-.29.092-.617.138-.983.138ZM24.001 21.7c.01 0 .02.005.028.012a.04.04 0 0 1 .01.029v.211c0 .278-.109.545-.304.741-.195.197-.46.307-.735.307h-3.898c-.275 0-.54-.11-.735-.307a1.052 1.052 0 0 1-.305-.74v-.212c0-.011.005-.021.012-.029a.038.038 0 0 1 .027-.011h5.9Z',
      fill: 'currentColor'
    },
    {
      d:
        'M6.295.14c-.235-.204-.431-.177-.665.027L.186 4.902C.104 4.973 0 5.093 0 5.185v17.174c0 .202.015.339.046.41.066.154.2.23.403.23h11.095c.245 0 .41-.217.41-.447.002-5.765 0-11.53-.005-17.295 0-.131-.035-.227-.105-.287A4775.585 4775.585 0 0 0 6.294.14ZM10.75 21.8c0-5.398 0-10.796-.005-16.194a4710.194 4710.194 0 0 0-4.767-4.149L1.205 5.61V21.8h9.545ZM6.42 1.072h.002-.002Zm1.56 3.419a2.818 2.818 0 0 1 0 3.99 2.835 2.835 0 0 1-4.005 0 2.818 2.818 0 0 1 0-3.99 2.835 2.835 0 0 1 4.004 0Zm-.853.848a1.62 1.62 0 0 1-1.15 2.77A1.629 1.629 0 0 1 4.35 6.485a1.62 1.62 0 0 1 1.627-1.623c.431 0 .845.171 1.15.476Zm14.157 6.911-.003-.002a.365.365 0 0 0-.095-.009h-.003c-2.33-.005-4.662-.005-6.996-.002v7.233h9.699l-.001-3.374c0-.592-.03-.948-.064-1.124v-.001c-.256-1.319-1.212-2.347-2.537-2.722Zm3.058 8.41H13.025a.03.03 0 0 1-.03-.03v-9.541c0-.027.012-.04.039-.04 2.719-.005 5.436-.005 8.152 0 .154 0 .295.019.423.055 1.748.495 3.04 1.87 3.383 3.64.058.295.086.745.086 1.352v4.04c.002.457-.337.525-.736.525Zm-11.308-8.42h.002-.002Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    },
    {
      d:
        'M21.96 16.048c0 1.112-.872 2.014-1.948 2.014-1.077 0-1.95-.902-1.95-2.014 0-1.113.873-2.014 1.95-2.014 1.076 0 1.949.901 1.949 2.014Z',
      fill: 'currentColor'
    },
    {
      d:
        'M19.4 8.814c-.857-1.37-2.308-2.264-3.966-2.292a83.16 83.16 0 0 0-1.251-.002v2.294H19.4Zm1.355 0c-.974-2.029-2.95-3.444-5.302-3.483-.347-.005-1.156-.005-2.427.002-.021 0-.032.011-.032.032V9.97c0 .024.012.037.037.037h8.117a.03.03 0 0 0 .015-.004.034.034 0 0 0 .017-.038 7.033 7.033 0 0 0-.032-.113V9.85a6.56 6.56 0 0 0-.393-1.035Z',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'sale-request',
  type: 'regular'
}
