import { CIcon } from '~/icons/types'
export const ciWorker: CIcon = {
  width: 450.2,
  height: 512,
  paths: [
    {
      d:
        'M315.1 286.9c-28.8 0-42.7 16.1-90 16.1s-61.1-16.1-90-16.1C60.5 286.9 0 347.4 0 422v41.8C0 490.4 21.6 512 48.2 512H402c26.6 0 48.2-21.6 48.2-48.2V422c0-74.6-60.5-135.1-135.1-135.1zm22.3 192.9H112.8V415h224.6v64.8zM292 324v59.9H158.2V324c15.1 4.7 34.7 11.2 66.9 11.2 32.1-.1 51.7-6.5 66.9-11.2zM32.2 463.8V422c0-54.1 41.9-98.5 94.9-102.6v64.5H97.2c-8.6 0-15.6 7-15.6 15.6v80.4H48.2c-8.8-.1-16-7.3-16-16.1zm385.8 0c0 8.8-7.2 16.1-16.1 16.1h-33.4v-80.4c0-8.6-7-15.6-15.6-15.6H323v-64.5c53.1 4.1 94.9 48.5 94.9 102.6v41.8z'
    },
    {
      d:
        'M96.5 145.9c.9 70.2 58.2 126.9 128.6 126.9 70.5 0 127.7-56.7 128.6-126.9 8.3-.4 14.9-7.2 14.9-15.5C368.6 58.5 310.1 0 238.2 0h-27C139.4 0 80.9 58.5 80.9 130.4c0 8.6 7 15.5 15.6 15.5zm128.6 94.8c-52.6 0-95.5-42.4-96.4-94.7h192.9c-1 52.3-43.9 94.7-96.5 94.7zM211.3 31.2h27c49.4 0 90.5 36.3 98 83.6h-223c7.5-47.3 48.5-83.6 98-83.6z'
    }
  ],
  name: 'worker',
  type: 'regular'
}
