import { CIcon } from '~/icons/types'
export const ciGrid: CIcon = {
  width: 512,
  height: 512,
  minY: -50,
  paths: [
    {
      d:
        'M80 368v-96h96v96h-96v0zM32 368q1 20 14 34v0q14 13 34 14h96q20 -1 34 -14q13 -14 14 -34v-96q-1 -20 -14 -34q-14 -13 -34 -14h-96q-20 1 -34 14q-13 14 -14 34v96v0zM80 112v-96h96v96h-96v0zM32 112q1 20 14 34v0q14 13 34 14h96q20 -1 34 -14q13 -14 14 -34v-96 q-1 -20 -14 -34q-14 -13 -34 -14h-96q-20 1 -34 14q-13 14 -14 34v96v0zM432 368h-96v-96h96v96v0zM336 416h96q20 -1 34 -14q13 -14 14 -34v-96q-1 -20 -14 -34q-14 -13 -34 -14h-96q-20 1 -34 14q-13 14 -14 34v96q1 20 14 34q14 13 34 14v0zM336 112v-96h96v96h-96v0z M288 112q1 20 14 34v0q14 13 34 14h96q20 -1 34 -14q13 -14 14 -34v-96q-1 -20 -14 -34q-14 -13 -34 -14h-96q-20 1 -34 14q-13 14 -14 34v96v0z',
      fill: 'currentColor'
    }
  ],
  name: 'grid',
  type: 'regular'
}
