import { CIcon } from '~/icons/types'

export const ciChart: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M19.875 16.5h.75a.376.376 0 0 0 .375-.375V4.875a.376.376 0 0 0-.375-.375h-.75a.376.376 0 0 0-.375.375v11.25c0 .206.169.375.375.375Zm-4.5 0h.75a.376.376 0 0 0 .375-.375v-6.75A.376.376 0 0 0 16.125 9h-.75a.376.376 0 0 0-.375.375v6.75c0 .206.169.375.375.375Zm-9 0h.75a.376.376 0 0 0 .375-.375v-3.75A.376.376 0 0 0 7.125 12h-.75a.376.376 0 0 0-.375.375v3.75c0 .206.169.375.375.375Zm4.5 0h.75a.376.376 0 0 0 .375-.375v-9.75A.376.376 0 0 0 11.625 6h-.75a.376.376 0 0 0-.375.375v9.75c0 .206.169.375.375.375Zm12.75 3H1.5V3.375A.375.375 0 0 0 1.125 3h-.75A.375.375 0 0 0 0 3.375V20.25c0 .414.336.75.75.75h22.875a.375.375 0 0 0 .375-.375v-.75a.375.375 0 0 0-.375-.375Z',
      fill: 'currentColor'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'chart',
  type: 'regular'
}
