import { CIcon } from '~/icons/types'
export const ciChatBubble: CIcon = {
  width: 967,
  height: 963,
  paths: [
    {
      d:
        'M910 264c24.3 46 41.5 94 51.5 144 13.3 85.3 4 168-28 248-30.7 78-78.7 143.7-144 197-66.7 55.3-142.7 90.3-228 105-20 3.3-46.7 5-80 5-86.7 0-165-20-235-60l-200 55h-20c-10-2.7-17.3-8.3-22-17-4.7-8.7-5.7-18-3-28l60-195C36.8 673.3 19.6 623.7 10 569 .3 514.3-.9 460.7 6.5 408c7.3-49.3 22.8-97 46.5-143s53.2-86.7 88.5-122c62.7-62.7 135.7-105 219-127 80.7-21.3 161.3-21.3 242 0 83.3 22 156.3 64.3 219 127 34.6 34.7 64.1 75 88.5 121zm-36.5 73c-26-67.3-67-123.7-123-169-57.3-46.7-123.7-76.7-199-90s-148.3-6.3-219 21c-68.7 26-126 67-172 123-48 58.7-77.7 125.7-89 201-7.3 47.3-6.3 95.2 3 143.5s26.7 93.8 52 136.5l5 10-50 175 180-50 10 5c42.7 25.3 88.2 42.7 136.5 52 48.3 9.3 96.2 10.3 143.5 3 58-10 111.3-31.2 160-63.5S800.7 761.7 833 713s53.5-102 63.5-160c11.3-75.3 3.6-147.3-23-216zM246 447.5c9.7-9.7 21.5-14.5 35.5-14.5s25.8 4.8 35.5 14.5 14.5 21.5 14.5 35.5-4.8 25.8-14.5 35.5-21.5 14.5-35.5 14.5-25.8-4.8-35.5-14.5-14.5-21.5-14.5-35.5 4.8-25.8 14.5-35.5zm200 0c9.7-9.7 21.5-14.5 35.5-14.5s25.8 4.8 35.5 14.5 14.5 21.5 14.5 35.5-4.8 25.8-14.5 35.5-21.5 14.5-35.5 14.5-25.8-4.8-35.5-14.5-14.5-21.5-14.5-35.5 4.8-25.8 14.5-35.5zm200 0c9.7-9.7 21.5-14.5 35.5-14.5s25.8 4.8 35.5 14.5 14.5 21.5 14.5 35.5-4.8 25.8-14.5 35.5-21.5 14.5-35.5 14.5-25.8-4.8-35.5-14.5-14.5-21.5-14.5-35.5 4.8-25.8 14.5-35.5z',
      fill: 'currentColor'
    }
  ],
  name: 'chat-bubble',
  type: 'regular'
}
