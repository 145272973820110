



















import { computed, defineComponent, useRouter } from '@nuxtjs/composition-api'
import { INHOUSE_DASHBOARD_VISITED } from '~/models/advertising/types'
import { AccessToggleName } from '~/models/user/access-toggles'
import { HeaderDropdownLink } from '~/models/header/header-dropdown'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import RouteGuardService from '~/services/RouteGuardService'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { useDeps } from '~/compositions/dependency-container'
import { useNamespacedStore } from '~/compositions/store'
import { useI18n } from '~/compositions/i18n'
import { useCookies } from '~/compositions/useCookies'
import CNewBadge from '~/components/shared/configurable/badge/CNewBadge.vue'
import { ciLayout } from '~/icons/source/regular/layout'
import { ciGrid } from '~/icons/source/regular/grid'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciBell } from '~/icons/source/regular/bell'
import { ciChatBubble } from '~/icons/source/regular/chat-bubble'
import { ciChart } from '~/icons/source/regular/chart'
import { ciCalendarFa } from '~/icons/source/regular/calendar-fa'
import { ciAuditShop } from '~/icons/source/regular/audit-shop'
import { ciAuditRequest } from '~/icons/source/regular/audit-request'
import { ciAudit } from '~/icons/source/regular/audit'
import { ciWorker } from '~/icons/source/regular/worker'
import { ciFinancialPlan } from '~/icons/source/regular/financial-plan'
import { ciParking } from '~/icons/source/regular/parking'
import { ciClassifiedTransfer } from '~/icons/source/regular/classified-transfer'
import { ciSaleRequest } from '~/icons/source/regular/sale-request'
import { ciWantedParts } from '~/icons/source/regular/wanted-parts'
import { ciOrdersBox } from '~/icons/source/regular/orders-box'
import { ciEshop } from '~/icons/source/regular/e-shop'
import { ciTicket } from '~/icons/source/regular/ticket'
import { useAnalytics } from '~/compositions/analytics'
import { ciBullhorn } from '~/icons/source/regular/bullhorn'

export default defineComponent({
  components: { CNewBadge },
  props: {
    inMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(_, { emit }) {
    const { t } = useI18n()
    const router = useRouter()
    const cookies = useCookies()
    const analytics = useAnalytics()

    const [legacyUrlService, routeGuardService] = useDeps(
      LegacyUrlService,
      RouteGuardService
    )
    const { state, getters } = useNamespacedStore<UserState>(USER_NS)

    const userHasPermission = getters('hasPermission')
    const isOfTypeDealer = getters('isOfTypeDealer')
    const userHasAccessTo = getters('userHasAccessTo')
    const isGuest = getters('isGuest')
    const isAuditsUser = getters('isAuditsUser')

    const accountUrl = computed(() => legacyUrlService.getAccountUrl())
    const notificationsUrl = computed(() => {
      return router.resolve({ name: '__account_notifications' }).href
    })
    const announcementsUrl = computed(() => {
      return router.resolve({ name: '__account_announcements' }).href
    })
    const userCanTrade = computed(() => routeGuardService.userCanTrade())
    const rogueClassifiedsCount = computed(() =>
      state.rogueClassifiedsCount > 99 ? '99+' : state.rogueClassifiedsCount
    )
    const unreadNotifications = computed(() =>
      state.unreadNotifications > 99 ? '99+' : state.unreadNotifications
    )
    const unreadMessages = computed(() =>
      state.unreadMessages > 99 ? '99+' : state.unreadMessages
    )
    const unreadParkingNotifications = computed(() =>
      state.unreadParkingNotifications > 99
        ? '99+'
        : state.unreadParkingNotifications
    )
    const unreadAnnouncements = computed(() =>
      state.unreadAnnouncements > 99 ? '99+' : state.unreadAnnouncements
    )
    const marketplaceSellerNewOrdersCount = computed(() =>
      getters('marketplaceSellerNewOrdersCount') > 99
        ? '99+'
        : getters('marketplaceSellerNewOrdersCount')
    )
    const userMenu = computed(() => {
      let userMenuArray = [] as HeaderDropdownLink[]

      const rogueClassifieds = {
        title: t('transfer classifieds::header'),
        icon: ciClassifiedTransfer,
        url: router.resolve({
          name: '__account_rogue_classifieds'
        }).href,
        hide: !rogueClassifiedsCount.value,
        label: rogueClassifiedsCount.value
      }
      const controlPanel = {
        title: t('control panel'),
        icon: ciLayout,
        url: accountUrl.value
      }

      const myClassifieds = {
        title: t('my classifieds'),
        icon: ciFolderOpen,
        url: '/classifieds/my/'
      }

      const eshop = {
        title: `E-shop`,
        url: router.resolve({
          name: '__account_eshop'
        }).href,
        icon: ciEshop,
        label: marketplaceSellerNewOrdersCount.value || t('new::neoupper'),
        hide: !routeGuardService.userCanBeMarketplaceSeller()
      }

      const orders = {
        title: t('orders'),
        url: router.resolve({
          name: '__account_orders'
        }).href,
        icon: ciOrdersBox,
        hide: !routeGuardService.userCanBeMarketplaceBuyer()
      }

      const parking = {
        title: `${t('parked::parking_btn')} / ${t('favorites')}`,
        icon: ciParking,
        url: router.resolve({ name: '__account_parking' }).href,
        hide: !routeGuardService.userHasAccessToParking(),
        label: unreadParkingNotifications.value
      }

      const notifications = {
        title: t('notifications'),
        icon: ciBell,
        url: notificationsUrl.value,
        hide: !notificationsUrl.value,
        label: unreadNotifications.value
      }

      const announcements = {
        title: t('announcements'),
        icon: ciBullhorn,
        url: announcementsUrl.value,
        label: unreadAnnouncements.value
      }

      const messages = {
        title: t('messages'),
        icon: ciChatBubble,
        url: router.resolve({
          name: '__account_messages'
        }).href,
        label: unreadMessages.value,
        hide: !routeGuardService.userCanReceiveMessages()
      }

      const ads = {
        title: t('advertise'),
        icon: ciGrid,
        url: router.resolve({
          name: cookies.get(INHOUSE_DASHBOARD_VISITED)
            ? '__advertising_dashboard'
            : '__advertising'
        }).href,
        hide: !routeGuardService.userCanUseInHouseAds(),
        useNuxtLink: true
      }

      if (isOfTypeDealer) {
        userMenuArray = [
          rogueClassifieds,
          controlPanel,
          myClassifieds,
          eshop,
          orders,
          parking,
          notifications,
          messages,
          {
            title: t('wanted_parts'),
            icon: ciWantedParts,
            url: router.resolve({
              name: '__account_wanted_parts'
            }).href,
            label: t('new::neoupper'),
            hide: !userHasAccessTo(AccessToggleName.WANTED_PARTS_ACCESS)
          },
          {
            title: t('buy_requests'),
            icon: ciSaleRequest,
            url: router.resolve({
              name: '__account_sale_requests'
            }).href,
            hide: !userHasAccessTo(AccessToggleName.SALE_REQUESTS_BUY)
          },
          ads,
          {
            title: t('statistics'),
            icon: ciChart,
            url: '/account/statistics/',
            hide: !userHasPermission('classifieds.stats')
          },
          {
            title: t('tickets'),
            url: router.resolve({
              name: '__account_tickets'
            }).href,
            label: state.hasTickets ? '!' : '',
            icon: ciTicket
          },
          {
            title: t('subscription renewal'),
            icon: ciCalendarFa,
            url: router.resolve({
              name: '__account_subscriptions'
            }).href
          }
        ]
        if (unreadAnnouncements.value) {
          userMenuArray.push(announcements)
        }
      }

      if (isGuest) {
        userMenuArray = [
          rogueClassifieds,
          controlPanel,
          myClassifieds,
          orders,
          parking,
          notifications,
          messages,
          {
            title: t('sale_requests'),
            icon: ciSaleRequest,
            url: router.resolve({
              name: '__account_sale_requests'
            }).href,
            hide: !userHasAccessTo(AccessToggleName.SALE_REQUESTS_SELL)
          },
          ads
        ]
        if (unreadAnnouncements.value) {
          userMenuArray.push(announcements)
        }
      }

      if (isAuditsUser) {
        userMenuArray = [
          controlPanel,
          notifications,
          messages,
          {
            title: t('audit shops::katastimata'),
            icon: ciAuditShop,
            url: router.resolve({ name: '__account_audits_shops' }).href,
            hide: !userHasAccessTo(AccessToggleName.AUDIT_PANEL_SHOPS)
          },
          {
            title: t('audit requests'),
            icon: ciAuditRequest,
            url: router.resolve({
              name: '__account_audits_requests'
            }).href,
            hide: !userHasAccessTo(AccessToggleName.AUDIT_PANEL_AUDIT_REQUESTS)
          },
          {
            title: t('audits::elegxoi'),
            icon: ciAudit,
            url: router.resolve({
              name: '__account_audits_external_certificates'
            }).href
          },
          {
            title: t('auditors'),
            icon: ciWorker,
            url: router.resolve({
              name: '__account_audits_auditors'
            }).href,
            hide:
              !userHasAccessTo(AccessToggleName.AUDIT_PANEL_AUDITORS) || true // temporarily disable auditors page
          },
          {
            title: t('plans::pricing'),
            icon: ciFinancialPlan,
            url: router.resolve({
              name: '__account_audits_packages'
            }).href,
            hide: !userHasAccessTo(AccessToggleName.AUDIT_PANEL_AUDIT_PACKAGES)
          }
        ]
      }

      return userMenuArray
    })

    function handleLinkClick({
      link,
      event
    }: {
      link: HeaderDropdownLink
      event: Event
    }) {
      if (isOfTypeDealer) {
        analytics.recordEvent({
          namespace: 'n_menu_item_click',
          label: link.url,
          action: 'click'
        })
      }
      emit('click', { link, event })
    }

    return {
      accountUrl,
      notificationsUrl,
      userCanTrade,
      userMenu,
      handleLinkClick
    }
  }
})
