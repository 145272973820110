




















































































import { faSignInAlt, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { mapState, mapGetters } from 'vuex'
import CreditsAnalysisBadges from '~/components/car/header/CreditsAnalysisBadges.vue'
import UserSubmenu from '~/components/car/header/UserSubmenu.vue'
import AdminSubmenu from '~/components/shared/header/AdminSubmenu.vue'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import { formatNumber } from '~/utils/number'
import { ADMINUSER_NS } from '~/store/modules/shared/admin/user/state'
import { APP_NS } from '~/store/modules/shared/app/state'
import PlotUserSubmenu from '~/components/plot/header/PlotUserSubmenu.vue'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    PlotUserSubmenu,
    CreditsAnalysisBadges,
    UserSubmenu,
    AdminSubmenu,
    CAvatar
  },
  props: {
    forParking: {
      type: Boolean,
      required: false,
      default: false
    },
    hideUserInfo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      adminSearchbarFocused: false
    }
  },
  computed: {
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    ...mapState(USER_NS, {
      username: 'name',
      avatar: 'avatar',
      unreadMessages: 'unreadMessages',
      rogueClassifiedsCount: 'rogueClassifiedsCount'
    }),
    ...mapGetters(USER_NS, {
      isAdmin: 'isAdmin',
      totalCredits: 'totalCredits',
      isAnon: 'isSingleOrAnon'
    }),
    ...mapState(ADMINUSER_NS, {
      adminExtras: 'adminExtras'
    }),
    goBackAsText() {
      return this.$t('go back as admin')
    },
    logoutUrl() {
      const legacyUrlService = this.$dep(LegacyUrlService)
      return legacyUrlService.getUserLogoutUrl()
    },
    icons: () => ({ unlock: faUnlock, signInAlt: faSignInAlt }),
    totalCreditsFormatted() {
      return formatNumber(this.totalCredits)
    },
    showCredits() {
      return (this.totalCredits || this.totalCredits === 0) && !this.isPlot
    },
    showGoBack() {
      return this.adminExtras?.suas?.goBack
    }
  },
  methods: {
    async adminMouseLeave() {
      if (!this.adminSearchbarFocused && this.$refs.adminmenu) {
        await this.$nextTick()
        this.$refs.adminmenu.resetSearchValue()
      }
    }
  }
})
