











































import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import { USER_NS } from '../../../../../store/modules/shared/user/state'
import { ciCredit } from '../../../../../icons/source/solid/credit'
import { ciCredit as ciCreditRegular } from '../../../../../icons/source/regular/credit'
import { formatNumber } from '../../../../../utils/number'

export default defineComponent({
  data() {
    return {}
  },
  computed: {
    ...mapGetters(USER_NS, {
      totalCredits: 'totalCredits',
      paidCredits: 'paidCredits',
      freeCredits: 'freeCredits'
    }),
    icons() {
      return {
        credit: ciCredit,
        creditRegular: ciCreditRegular
      }
    }
  },
  methods: {
    formatNumber(num: number) {
      return formatNumber(num)
    }
  }
})
