import { CIcon } from '~/icons/types'
export const ciAuditShop: CIcon = {
  width: 542,
  height: 512,
  paths: [
    {
      d:
        'M526.4 512H442c-8.6 0-15.6-7-15.6-15.6V226.5H115.7v269.9c0 8.6-7 15.6-15.6 15.6H15.6C7 512 0 505 0 496.4V149.9c0-5.8 3.2-11.1 8.3-13.8L263.8 1.8c4.5-2.4 10-2.4 14.5 0l255.4 134.3c5.1 2.7 8.3 8 8.3 13.8v346.5c0 8.6-6.9 15.6-15.6 15.6zm-68.8-31.2h53.2V159.3L271 33.2 31.2 159.3v321.5h53.2V210.9c0-8.6 7-15.6 15.6-15.6h342c8.6 0 15.6 7 15.6 15.6v269.9z'
    },
    {
      d:
        'M321.1 149.8H221c-8.6 0-15.6-7-15.6-15.6s7-15.6 15.6-15.6h100.1c8.6 0 15.6 7 15.6 15.6s-7 15.6-15.6 15.6z'
    },
    {
      d:
        'M381.5 290.7H160.6c-8.6 0-15.6-7-15.6-15.6s7-15.6 15.6-15.6h220.9c8.6 0 15.6 7 15.6 15.6s-7 15.6-15.6 15.6z'
    },
    {
      d:
        'M381.5 355.2H160.6c-8.6 0-15.6-7-15.6-15.6s7-15.6 15.6-15.6h220.9c8.6 0 15.6 7 15.6 15.6s-7 15.6-15.6 15.6z'
    },
    {
      d:
        'M381.5 419.6H160.6c-8.6 0-15.6-7-15.6-15.6s7-15.6 15.6-15.6h220.9c8.6 0 15.6 7 15.6 15.6s-7 15.6-15.6 15.6z'
    },
    {
      d:
        'M381.5 484H160.6c-8.6 0-15.6-7-15.6-15.6s7-15.6 15.6-15.6h220.9c8.6 0 15.6 7 15.6 15.6s-7 15.6-15.6 15.6z'
    }
  ],
  name: 'audit-shop',
  type: 'regular'
}
