import { CIcon } from '~/icons/types'
export const ciSearchLight: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M500 496q6 7 0 13-3 3-7 3t-7-3L342 359q-56 47-130 47-83 0-142.5-59.5T10 203 69.5 59.5 212.5 0t143 59.5T415 203t-59 143zM212.5 387q75.5 0 129.5-54t54-130-54-130-129.5-54T83 73 29 203t54 130 129.5 54z',
      fill: 'currentColor'
    }
  ],
  name: 'search-light',
  type: 'light'
}
