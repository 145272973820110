import { CIcon } from '~/icons/types'
export const ciAuditRequest: CIcon = {
  width: 483.6,
  height: 512,
  paths: [
    {
      d:
        'M411.2 0H72.4C32.5 0 0 32.5 0 72.4v241.3C0 353.5 32.5 386 72.4 386h29.2v110.5c0 6.3 3.8 12 9.7 14.4 1.9.8 3.9 1.1 5.9 1.1 4.1 0 8.1-1.6 11.1-4.7L246.8 386h164.5c39.9 0 72.4-32.5 72.4-72.4V72.4C483.6 32.5 451.1 0 411.2 0zm41.4 313.6c0 22.8-18.5 41.3-41.3 41.3h-171c-4.2 0-8.2 1.7-11.1 4.7l-96.6 98.8v-87.9c0-8.6-7-15.5-15.5-15.5H72.4c-22.8 0-41.3-18.5-41.3-41.3V72.4C31 49.6 49.6 31 72.4 31h338.9c22.8 0 41.3 18.5 41.3 41.3v241.3z'
    },
    {
      d:
        'M319.9 221c19.1-2.9 37.4-11.7 52-26.3 27.9-27.9 33.6-70.8 14.1-104.4l-8.6-14.8-51.6 37.3-3.7-3.8 36.4-46.6-14.4-9.2c-13.6-8.7-33.2-12.2-53.5-9.6-20.4 2.6-39.2 11.1-51.4 23.3-17.7 17.7-27.5 40.4-27.5 63.9 0 8.8 1.4 17.4 4 25.8l-.1.1-38.7-38.7 4.8-4.8L167.3 86l-52.7-33.5-30.5 30.6 34.8 48 32 12.8 4-4 39.2 39.2-87.6 91.4c-16.9 17.1-16.9 44.8.1 61.8 8.5 8.5 19.7 12.8 30.9 12.8s22.4-4.3 30.9-12.8l66.7-69.6 63.6 67.8.3.4c8.8 8.8 20.3 13.2 31.8 13.2s23.1-4.4 31.8-13.2c17.5-17.5 17.6-45.9.2-63.5L319.9 221zM136.2 108.9l-14.3-19.7 24.9 15.8 1.7 3.1-4 4-8.3-3.2zm124.9-20c10.1-10.1 27.3-15.1 42.6-15.1 2.1 0 4.2.1 6.2.3L281.1 111l40.9 42.8 43.5-31.4c4 19.8-4.1 38.9-15.5 50.3-24.5 24.5-64.3 24.5-88.8 0-11.9-11.9-18.4-26.7-18.4-41.9-.1-15.2 6.5-30.1 18.3-41.9zM146.5 310.3c-5 4.9-13 4.8-17.9-.1-5-5-5-13 0-18L231 185.4c2.5 3.2 5.2 6.3 8.2 9.2 3.2 3.2 6.5 6 10 8.6L146.5 310.3zm194.4-1.4c-2.6 2.6-6.2 4.1-9.9 4.1s-7.1-1.4-9.7-4l-64.5-68.8 19.6-20.4 64.2 68.9.4.4c2.6 2.6 4.1 6.2 4.1 9.9s-1.5 7.2-4.2 9.9z'
    }
  ],
  name: 'audit-request',
  type: 'regular'
}
