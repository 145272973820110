























































































































































































































































































































































import { ciPlotLabeled } from '~/icons/source/brand/plot-labeled'
import { mapGetters, mapState } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { I18nLocale } from '~/models/shared/types'
import { I18N_NS } from '~/store/modules/shared/i18n/state'
import {
  faBuilding,
  faCaretDown,
  faPlus,
  faPlusSquare,
  faUser,
  faUnlock,
  faMap,
  faHeart,
  faBell
} from '@fortawesome/free-solid-svg-icons'
import { capitalizeAllWords } from '~/utils/string'
import { ciAngleDownLight } from '~/icons/source/solid/angle-down-light'
import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons'
import PlotMobileMenu from '~/components/plot/header/PlotMobileMenu.vue'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import { defineComponent } from '@nuxtjs/composition-api'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import UserDropdownContent from '~/components/car/header/UserDropdownContent.vue'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import CreditsBadge from '~/components/shared/header/CreditsBadge.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import { ciBell } from '~/icons/source/regular/bell'
import { ciHeart } from '~/icons/source/regular/heart'
import { ADMINUSER_NS } from '~/store/modules/shared/admin/user/state'
import { hideAllPoppers } from 'floating-vue'
import { ciSignInAlt } from '~/icons/source/regular/sign-in-alt'
import { ciUserPlus } from '~/icons/source/regular/user-plus'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { HeaderDropdownId } from '~/models/header/header-dropdown'
import { TranslateResult } from 'vue-i18n'
import { defineVue3AsyncComponent } from '~/utils/nuxt3-migration'

interface Link {
  title: TranslateResult
  extraClasses?: string
  url: string
  useNuxtLink: boolean
}

export default defineComponent({
  components: {
    NotificationCenterPopover: defineVue3AsyncComponent(() =>
      import(
        '~/components/shared/configurable/notification-center/NotificationCenterPopover.vue'
      )
    ),
    CreditsBadge,
    UserDropdownContent,
    CAvatar,
    PlotMobileMenu,
    AdminSubmenu: defineVue3AsyncComponent(() =>
      import('~/components/shared/header/AdminSubmenu.vue')
    ),
    AdminTopBar: defineVue3AsyncComponent(() =>
      import('~/components/shared/header/AdminTopBar.vue')
    ),
    CImg
  },
  props: {
    availableLocales: {
      type: Array,
      required: true
    }
  },
  data() {
    const menusMap = new Map([
      [
        'sale',
        {
          title: this.$t('sale'),
          links: [] as Link[]
        }
      ],
      [
        'rent',
        {
          title: this.$t('rent'),
          links: [] as Link[]
        }
      ],
      [
        'booking',
        {
          title: this.$t('booking'),
          links: [] as Link[]
        }
      ],
      [
        'wanted',
        {
          title: this.$t('wanted'),
          links: [] as Link[]
        }
      ],
      [
        'agents',
        {
          title: this.$t('agents'),
          links: [] as Link[]
        }
      ],
      [
        'information',
        {
          title: this.$t('information'),
          links: [] as Link[],
          useNuxtLink: null as null | Boolean
        }
      ]
    ])
    return {
      showMobileMenu: false,
      hoverReset: false,
      menusMap,
      subMenus: Array.from(menusMap, ([_key, value]) => value)
    }
  },
  computed: {
    headerDropdownId: () => HeaderDropdownId,
    ...mapState(USER_NS, {
      username: 'name',
      unreadMessages: 'unreadMessages',
      unreadNotifications: 'unreadNotifications',
      avatar: 'avatar'
    }),
    ...mapGetters(USER_NS, {
      isAgent: 'isOfTypeDealer',
      isAdmin: 'isAdmin',
      isGuest: 'isGuest',
      isAnon: 'isSingleOrAnon',
      userHasPermission: 'hasPermission'
    }),
    ...mapGetters(I18N_NS, {
      localeIsActive: 'localeIsActive',
      locale: 'locale'
    }),
    ...mapState(ADMINUSER_NS, {
      adminExtras: 'adminExtras'
    }),
    ...mapDeps({ languageSwitcher: LanguageSwitcher }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    isNotificationPage() {
      return this.$route.name === '__account_notifications_list'
    },
    icons() {
      return {
        plot: ciPlotLabeled,
        caretDown: faCaretDown,
        angleDown: ciAngleDownLight,
        plusSquare: faPlusSquare,
        plus: faPlus,
        user: faUser,
        building: faBuilding,
        envelopeRegular: farEnvelope,
        unlock: faUnlock,
        map: faMap,
        bell: ciBell,
        bellFull: faBell,
        heart: ciHeart,
        heartFull: faHeart,
        signInAlt: ciSignInAlt,
        register: ciUserPlus
      }
    },
    localeCodeToFlag: () =>
      new Map([
        [I18nLocale.EN, require('~/assets/img/flags/1x1/en.svg')],
        [I18nLocale.EL, require('~/assets/img/flags/1x1/el.svg')]
      ]),
    offerBtnLink() {
      if (this.isAdmin) {
        return '/admin/users/search/?site=plot'
      }
      return this.$dep(LegacyUrlService).getNewClassifiedUrl()
    },
    offerBtnText() {
      if (this.isAdmin) {
        return this.$t('users')
      }
      if (this.isAgent) {
        return this.$t('new classified')
      }
      return capitalizeAllWords(this.$t('free classified'))
    },
    notificationsAtMaxCount() {
      return this.unreadNotifications && this.unreadNotifications > 99
    },
    inIndex() {
      return this.$route.name === '__index'
    },
    inView() {
      return this.$route.name === '__classifieds_view'
    },
    inSearchMap() {
      return this.$route.name?.startsWith('__classifieds_search_plot_map')
    },
    inCompare() {
      return this.$route.name === '__compare'
    },
    noShadow() {
      return this.inView || this.inSearchMap || this.inCompare
    },
    inNotificationsPage() {
      return (
        this.$route.name === '__account_notifications' ||
        this.$route.name === '__notifications_view'
      )
    },
    showAdminUser() {
      return this.isAdmin || Boolean(this.adminExtras?.suas?.goBack)
    }
  },
  created() {
    if (!this.isMobile) {
      this.menusMap.delete('support')
      this.menusMap.delete('faq')
    }
    this.populateMenus()
  },
  methods: {
    populateMenus() {
      // @ts-ignore
      this.menusMap.set('sale', {
        ...this.menusMap.get('sale'),
        links: [
          {
            title: this.$t('all'),
            url: '/search/?category=20004',
            useNuxtLink: false
          },
          {
            title: this.$t('home'),
            url: '/search/?category=20007',
            useNuxtLink: false
          },
          {
            title: this.$t('commercial'),
            url: '/search/?category=20009',
            useNuxtLink: false
          },
          {
            title: this.$t('land'),
            url: '/search/?category=20011',
            useNuxtLink: false
          },
          {
            title: this.$t('other'),
            url: '/search/?category=20013',
            useNuxtLink: false
          }
        ]
      })
      // @ts-ignore
      this.menusMap.set('rent', {
        ...this.menusMap.get('rent'),
        links: [
          {
            title: this.$t('all'),
            url: '/search/?category=20003',
            useNuxtLink: false
          },
          {
            title: this.$t('home'),
            url: '/search/?category=20006',
            useNuxtLink: false
          },
          {
            title: this.$t('commercial'),
            url: '/search/?category=20008',
            useNuxtLink: false
          },
          {
            title: this.$t('land'),
            url: '/search/?category=20010',
            useNuxtLink: false
          },
          {
            title: this.$t('students'),
            url: '/search/?category=20014&category=20015&specials=25',
            useNuxtLink: false
          },
          {
            title: this.$t('roommates wanted'),
            url: '/search/?category=20006&specials=81',
            useNuxtLink: false
          },
          {
            title: this.$t('other'),
            url: '/search/?category=20012',
            useNuxtLink: false
          }
        ]
      })
      // @ts-ignore
      this.menusMap.set('booking', {
        ...this.menusMap.get('booking'),
        links: [
          {
            title: this.$t('all'),
            url: '/search/?category=20005',
            useNuxtLink: false
          },
          {
            title: this.$t('bungalow'),
            url: '/search/?category=20080',
            useNuxtLink: false
          },
          {
            title: this.$t('hostel'),
            url: '/search/?category=20082',
            useNuxtLink: false
          },
          {
            title: this.$t('loft'),
            url: '/search/?category=20083',
            useNuxtLink: false
          },
          {
            title: this.$t('studio'),
            url: '/search/?category=20087',
            useNuxtLink: false
          },
          {
            title: this.$t('villa'),
            url: '/search/?category=20088',
            useNuxtLink: false
          },
          {
            title: this.$t('apartment'),
            url: '/search/?category=20089',
            useNuxtLink: false
          },
          {
            title: this.$t('maisonette'),
            url: '/search/?category=20085',
            useNuxtLink: false
          },
          {
            title: this.$t('detached home'),
            url: '/search/?category=20081',
            useNuxtLink: false
          },
          {
            title: this.$t('hotel'),
            url: '/search/?category=20084',
            useNuxtLink: false
          },
          {
            title: this.$t('attic'),
            url: '/search/?category=20092',
            useNuxtLink: false
          },
          {
            title: this.$t('other'),
            url: '/search/?category=20086',
            useNuxtLink: false
          }
        ]
      })
      // @ts-ignore
      this.menusMap.set('wanted', {
        ...this.menusMap.get('wanted'),
        links: [
          {
            title: this.$t('all'),
            url: '/search/?category=20100',
            useNuxtLink: false
          },
          {
            title: this.$t('sale'),
            url: '/search/?category=20139',
            useNuxtLink: false
          },
          {
            title: `${this.$t('sale')} - ${this.$t('land')}`,
            extraClasses: 'sub-cat',
            url: '/search/?category=20172',
            useNuxtLink: false
          },
          {
            title: `${this.$t('sale')} - ${this.$t('commercial')}`,
            extraClasses: 'sub-cat',
            url: '/search/?category=20154',
            useNuxtLink: false
          },
          {
            title: `${this.$t('sale')} - ${this.$t('home')}`,
            extraClasses: 'sub-cat',
            url: '/search/?category=20140',
            useNuxtLink: false
          },
          {
            title: `${this.$t('sale')} - ${this.$t('other')}`,
            extraClasses: 'sub-cat',
            url: '/search/?category=20165',
            useNuxtLink: false
          },
          {
            title: this.$t('rent'),
            url: '/search/?category=20101',
            useNuxtLink: false
          },
          {
            title: `${this.$t('rent')} - ${this.$t('land')}`,
            extraClasses: 'sub-cat',
            url: '/search/?category=20113',
            useNuxtLink: false
          },
          {
            title: `${this.$t('rent')} - ${this.$t('commercial')}`,
            extraClasses: 'sub-cat',
            url: '/search/?category=20102',
            useNuxtLink: false
          },
          {
            title: `${this.$t('rent')} - ${this.$t('home')}`,
            extraClasses: 'sub-cat',
            url: '/search/?category=20125',
            useNuxtLink: false
          },
          {
            title: `${this.$t('rent')} - ${this.$t('other')}`,
            extraClasses: 'sub-cat',
            url: '/search/?category=20118',
            useNuxtLink: false
          },
          {
            title: this.$t('new wanted classified'),
            extraClasses: 'sub-action',
            url: '/classifieds/new/20100/',
            useNuxtLink: false
          }
        ]
      })
      // @ts-ignore
      this.menusMap.set('agents', {
        ...this.menusMap.get('agents'),
        links: [
          {
            title: this.$t('agents catalog'),
            url: '/agents/',
            useNuxtLink: false
          },
          {
            title: this.$t('agent registration'),
            extraClasses: 'sub-action',
            url: '/agents/register/',
            useNuxtLink: false
          },
          {
            title: this.$t('agent services'),
            url: '/information/faq/services_agents/',
            useNuxtLink: false
          }
        ]
      })
      // @ts-ignore
      this.menusMap.set('information', {
        ...this.menusMap.get('information'),
        links: [
          {
            title: this.$t('support'),
            url: '/information/contact/',
            useNuxtLink: true
          },
          {
            title: this.$t('agent registration'),
            url: '/agents/register/',
            useNuxtLink: true
          },
          {
            title: this.$t('user registration'),
            url: '/guests/register/',
            useNuxtLink: true
          },
          {
            title: this.$t('agent services'),
            url: '/information/faq/services_agents/',
            useNuxtLink: false
          },
          {
            title: this.$t('agents faq'),
            url: '/information/faq/agents/',
            useNuxtLink: false
          },
          {
            title: this.$t('users faq'),
            url: '/information/faq/users/',
            useNuxtLink: false
          },
          {
            title: this.$t('privacy policy'),
            url: '/information/faq/privacy/',
            useNuxtLink: false
          },
          {
            title: this.$t('learn about promotions'),
            url: '/landing/promotions/',
            useNuxtLink: true
          },
          {
            title: this.$t('property prices in greece'),
            url: '/price-trends/',
            useNuxtLink: true
          }
        ]
      })

      this.subMenus = Array.from(this.menusMap, ([_key, value]) => value)
    },
    linkClicked(link) {
      if (link && link.useNuxtLink) {
        this.hoverReset = true
        hideAllPoppers()
      }
    },
    triggerMenuClose() {
      if (this.$refs.mobilemenu) {
        this.$refs.mobilemenu.closeMenu()
      }
    }
  }
})
