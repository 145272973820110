var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showHeader)?_c('PlotHeader',{attrs:{"available-locales":_vm.availableLocales}}):_vm._e(),_vm._v(" "),_c('AppNotifications'),_vm._v(" "),_c('AppConfirmationModal'),_vm._v(" "),_c('AppSnackbar'),_vm._v(" "),(_vm.showGoogleOneTap)?_c('GoogleOneTap'):_vm._e(),_vm._v(" "),(_vm.isLoginModalVisible)?_c('CLoginModal'):_vm._e(),_vm._v(" "),(_vm.showFlashMessages)?_c('div',{staticClass:"tw-flex tw-pt-2 tw-w-full ",class:[
      _vm.inIndex || _vm.inAccount
        ? 'md:tw-bg-transparent tw-bg-primary'
        : 'tw-bg-transparent',
      {
        'md:tw-absolute md:tw-top-[47px] md:tw-z-[99] md:tw-bg-white/10 md:tw-backdrop-blur': _vm.inIndex
      }
    ]},[_c('CContainer',{staticClass:"tw-max-w-[1600px] !tw-px-2"},[_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('BackendFlashMessage'),_vm._v(" "),_c('client-only',[(_vm.clientFlashMessage)?_c('ClientFlashMessage',{attrs:{"client-flash-message":_vm.clientFlashMessage}}):_vm._e()],1)],1)])],1):_vm._e(),_vm._v(" "),_c('portal-target',{attrs:{"name":"carzilla-suggestion-select-container","multiple":""}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"dropdown","multiple":""}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"modal"}}),_vm._v(" "),_c('nuxt',{attrs:{"keep-alive":_vm.isMobile,"keep-alive-props":{
      include: ['MapSearchPage'],
      max: 1
    }}}),_vm._v(" "),(_vm.showFooter)?_c('PlotFooter',{attrs:{"available-locales":_vm.availableLocales}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }