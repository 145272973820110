import { CIcon } from '~/icons/types'
export const ciCredit: CIcon = {
  width: 938,
  height: 913,
  paths: [
    {
      d:
        'M179 842c56 42 117 65.3 183 70l189 1c70 0 135-21 195-63 58.7-40 105-94.3 139-163 35.3-71.3 53-148.2 53-230.5S920.3 297.3 885 226c-34-68.7-80.3-123-139-163C686 21 621 0 551 0L362 1c-66 4.7-127 28-183 70-54.7 41.3-98 95.3-130 162C16.3 302.3 0 376.8 0 456.5 0 536.2 16.3 610.7 49 680c32 67.3 75.3 121.3 130 162zm547.5-716.5c45.7 36.3 81.8 83.2 108.5 140.5 26.7 58.7 40 122 40 190s-13.3 131.7-40 191c-26.7 57.3-62.8 104.2-108.5 140.5S630.3 844.3 575 849c60.7-46.7 107.7-104.5 141-173.5s49-141.8 47-218.5c2-76.7-13.5-149.7-46.5-219S636.3 110.7 575 64c55.3 4.7 105.8 25.2 151.5 61.5zM106 258c28.7-59.3 67-106.3 115-141 49.3-36 102.8-54 160.5-54S492.7 81 542 117c48 34.7 86.3 81.7 115 141 28.7 61.3 43 127.5 43 198.5S685.7 593.7 657 655c-28.7 59.3-67 106.3-115 141-49.3 36-102.8 54-160.5 54S270.3 832 221 796c-48-34.7-86.3-81.7-115-141-28.7-61.3-43-127.7-43-199 0-70.7 14.3-136.7 43-198z',
      fill: 'currentColor'
    },
    {
      d:
        'M329.5 332c19-13.3 40.2-19.7 63.5-19 16 0 31.3 2.8 46 8.5s26.7 14.5 36 26.5l32-91c-16.7-12-34.8-21.3-54.5-28-19.7-6.7-39.8-10-60.5-10-37.3 0-71.3 10.3-102 31-32.7 21.3-57.7 49.7-75 85-19.3 38-29 78.5-29 121.5s9.7 83.8 29 122.5c17.3 34.7 42.3 63 75 85 30.7 20 64.3 30 101 30 20 0 39.8-3.3 59.5-10 19.7-6.7 38.2-16.3 55.5-29l-32-91c-9.3 12.7-21.3 21.8-36 27.5-14.7 5.7-29.7 8.5-45 8.5-22 .7-42.5-5.3-61.5-18s-33.8-29.3-44.5-50c-11.3-23.3-17-48-17-74s5.3-50.7 16-74c10-21.3 24.5-38.7 43.5-52z',
      fill: 'currentColor'
    }
  ],
  name: 'credit',
  type: 'regular'
}
