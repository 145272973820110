










































































































import { mapGetters } from 'vuex'
import { ADMINUSER_NS } from '~/store/modules/shared/admin/user/state'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { STORAGE_NS } from '~/store/modules/shared/storage/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    inMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    notGrouped: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      searchValue: '',
      showGrouped: true
    }
  },
  computed: {
    ...mapGetters(ADMINUSER_NS, {
      adminMenu: 'getAdminPagesGrouped',
      unGroupedPages: 'getAdminPagesUngrouped'
    }),
    ...mapGetters(STORAGE_NS, {
      getItem: 'getItem'
    }),
    icons() {
      return {
        caretRight: faCaretRight
      }
    },
    searchResults() {
      // maybe add keywords from back-end?? so i can search better
      if (this.searchValue && this.unGroupedPages.length) {
        return this.unGroupedPages.filter(p => {
          return (
            p.name.toLowerCase().includes(this.searchValue) ||
            p.url.toLowerCase().includes(this.searchValue)
          )
        })
      }
      return []
    }
  },
  created() {
    if (this.notGrouped || this.adminMenu.length <= 1) {
      this.showGrouped = false
    } else if (!this.inMobile) {
      const adminStorage = this.getItem('admin')

      if (adminStorage && typeof adminStorage.groupedMenu !== 'undefined') {
        this.showGrouped = adminStorage.groupedMenu
      }
    }
  },
  methods: {
    resetSearchValue() {
      this.searchValue = ''
    },
    showGroupedChanged(state) {
      this.$store.dispatch('storage/set', {
        admin: {
          groupedMenu: state
        }
      })
    }
  }
})
